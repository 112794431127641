
const digest = '0eb756255d37fd4dd79fcea1a870494adae866393e9b1bf4afaf10944114778c';
const css = `._invoiceSwitchBanner_sw0n5_1 {
  background: var(--color-white);
  width: 100%;
  max-width: 100vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--color--neutral-30);
  padding: 1rem 2.25rem 1rem 3.5rem;
}

._content_sw0n5_12 {
  position: relative;
  max-width: 50rem;
}

._body_sw0n5_17 {
  color: var(--color-neutral-10);
}

._title_sw0n5_21 {
  font-weight: bold;
}

._title_sw0n5_21 svg {
  position: absolute;
  width: 0.9rem;
  height: 0.9rem;
  right: calc(100% + 0.5rem);
  top: 0.2rem;
  color: var(--color-brand-10);
}

._title_sw0n5_21 svg ellipse {
  fill: currentColor !important;
}

._link_sw0n5_38 {
  appearance: none;
  background-color: rgba(255, 255, 255, 0);
  border: 0;
  color: var(--color-brand-10);
  cursor: pointer;
  display: inline;
  font-size: 1em;
  font-weight: 600;
  line-height: inherit;
  padding: 0;
  text-align: inherit;
  text-decoration: none;
}
`;

(function() {
  if (typeof document === 'undefined') {
    return;
  }
  if (!document.getElementById(digest)) {
    var el = document.createElement('style');
    el.id = digest;
    el.textContent = css;
    document.head.appendChild(el);
  }
})();
    
export default {"invoiceSwitchBanner":"_invoiceSwitchBanner_sw0n5_1","content":"_content_sw0n5_12","body":"_body_sw0n5_17","title":"_title_sw0n5_21","link":"_link_sw0n5_38"};
export { css, digest };
  