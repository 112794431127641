
const digest = 'fbaaaa32a13a5a39a8997f330f202d3d32b0b5c0de3e21ed4930b2815abc9627';
const css = `._dot-highlight_1y8i2_1 {
  height: 0.4rem;
  width: 0.4rem;
  background-color: #5840e0;
  border-radius: 50%;
  display: inline-block;
  margin-left: 0.2rem;
  margin-bottom: 0.1rem;
}
`;

(function() {
  if (typeof document === 'undefined') {
    return;
  }
  if (!document.getElementById(digest)) {
    var el = document.createElement('style');
    el.id = digest;
    el.textContent = css;
    document.head.appendChild(el);
  }
})();
    
export default {"dot-highlight":"_dot-highlight_1y8i2_1","dotHighlight":"_dot-highlight_1y8i2_1"};
export { css, digest };
  